/**
 * Variable Overrides.
 *
 * Modify this file to override the Bootstrap Framework variables. You can copy
 * these variables directly from ../bootstrap/less/variables.less to this file.
 */

// Set the proper directory for the Bootstrap Glyphicon font.
@icon-font-path: '../../vendor/bootstrap/fonts/';

//
// Variables
// --------------------------------------------------


//== Colors
//
//## Gray and brand colors for use across Bootstrap.

@brand-primary:         #f21d1d;


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Global text color on `<body>`.
@text-color:            #a0a0a0;

//** Link hover decoration.
@link-hover-decoration: none;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

@font-family-sans-serif:  "effra", Helvetica, Arial, sans-serif;
