.page-taxonomy-term {
  .banner-wrapper {
    height: 900px;
    @media (max-width: @screen-sm-max) {
      height: 600px;
    }
    a.cta {
      display: none;
    }
  }
  #content-banner {
    bottom: auto;
    top: 350px;
    left: 110px;
    h1 {
      width: 60%;
    }
  }
  .intro {
    margin-top: -300px;
    position: relative;
    z-index: 2;
    min-height: 580px;
    @media (max-width: @screen-sm-max) {
      min-height: auto;
    }
    > .container {
      display: flex;
      justify-content: flex-end;
      position: relative;
      @media (max-width: @screen-sm-max) {
        display: block;
      }
    }
    .intro-text {
      position: relative;
      z-index: 2;
      width: 55%;
      background-color: #fff;
      padding: 40px 50px;
      top: 80px;
      min-height: 280px;
      @media (max-width: @screen-sm-max) {
        position: static;
        width: 100%;
        min-height: auto !important;
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 50%;
      z-index: 1;
      @media (max-width: @screen-sm-max) {
        max-width: 100%;
        position: static;
      }
    }
  }
}
