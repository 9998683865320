body.admin-menu {
  #navigation {
    top: 29px;
  }
}
#header {

}

.trigger-menu {
	position: absolute;
	top: 40px;
	left: 20px;
	z-index: 20;
  text-decoration: none;
  display: none;
  @media (max-width: @screen-md-max) {
    display: block;
  }
	span {
		color: #FFF;
		font-size: 0.750rem;
		font-weight: 700;
		letter-spacing: 1px;
		line-height: normal;
		text-transform: uppercase;
    .transition(all .2s);
    @media (max-width: 330px) {
      display: none !important;
    }
	}
	svg, span {
		display: inline-block;
		vertical-align: middle;
	}
	svg {
    fill: #fff;
		margin-right: 10px;
		overflow: hidden;
		rect {
			transform-origin: left center;
			transform: rotate(0);
			.transition(all .2s);
		}
	}
	&:hover, &:focus {
		span {
			color: @brand-primary;
		}
		svg {
			fill: @brand-primary;
		}
		text-decoration: none;
	}
	&.open {
		svg {
			rect {
				&:nth-child(1) {
			    	transform: rotate(45deg);
					  x: -3px;
			    }
			    &:nth-child(2) {
		    	    width: 0%;
					opacity: 0;
			    }
			    &:nth-child(3) {
			    	transform: rotate(-45deg);
					  x: -3px;
			    }
			}
		}
	}
}

#logo {
	position: absolute;
	z-index: 8;
  @media (max-width: @screen-sm-max) {
    top: 120px;
  }
  top: 150px;
	left: 50%;
	margin-left: -125px;
	display: block;
	img {
		height: auto;
		width: 251px;
	}
}

#header-social {
	position: absolute;
	top: 40px;
	right: 40px;
	z-index: 20;
	margin: 0;
	padding: 0;
	list-style: none;
	li {
		float: left;
		a {
			display: block;
			color: #FFF;
			font-size: @font-size-large;
			.transition(all .2s);
			&:hover, &:focus {
				color: @brand-primary;
			}
		}
		& ~ li {
			margin-left: 15px;
		}
	}
}

#navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 12;
  .transition(all .2s);
  .container {
    position: relative;
  }
	ul {
    margin: 0;
    padding: 0;
    float: none;
    @media (max-width: @screen-md-max) {
      display: none;
      padding-top: 100px;
      padding-left: 40px;
    }
		li {
      display: inline-block;
      margin: 0 15px 0 0;
      float: none;
      position: relative;
      @media (max-width: @screen-md-max) {
        display: block;
        padding: 5px 0;
      }
			a {
				display: block;
				margin: 0;
				padding: 0 0 5px 0;
				color: #fff;
				font-size: 13px;
				font-weight: 500;
				line-height: normal;
				text-decoration: none;
        text-transform: uppercase;
        position: relative;
        .transition(all .2s);
        &:after {
          content: '';
          display: block;
          width: 0;
          height: 1px;
          background-color: #fff;
          position: absolute;
          left: 0;
          bottom: 0;
          .transition(all .2s);
          @media (max-width: @screen-md-max) {
            display: none;
          }
        }
				&:hover, &:focus {
          background-color: transparent;
          &:after {
            width: 100%;
            .transition(all .2s);
          }
        }
        &.active {
          border-bottom: 1px solid #fff;
          &:after {
            display: none;
          }
        }
        span {
          @media (max-width: @screen-md-max) {
            display: none;
          }
        }
      }
      .expand {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        font-size: 1.2rem;
        color: #fff;
        cursor: pointer;
        @media (max-width: @screen-md-max) {
          display: block;
        }
      }
			& ~li {
				//margin-top: 20px;
				margin-top: 10px;
      }
      &:hover {
        > ul {
          display: block;
          @media (max-width: @screen-md-max) {
            display: none;
          }
          > li {
            > a {
              background-color: transparent;
              &:hover {
                color: #000;
              }
            }
          }
        }
      }
      > ul {
        border: 0;
        > li {
          display: block;
          margin: 0;
          > a {
            color: #000;
            padding: 10px;
            font-size: .8rem;
            letter-spacing: 0;
            &:hover {
              background-color: #eee;
            }
          }
          & ~ li {
            margin: 0;
          }
        }
      }
		}
  }
  .cta-links {
    width: 300px;
    position: absolute;
    right: 0;
    top: 45px;
    display: flex;
    justify-content: space-between;
    @media (max-width: @screen-sm-max) {
      right: 20px;
      top: 40px;
    }
    @media (max-width: @screen-xs-min) {
      top: 37px;
      right: 10px;
      width: 250px;
      left: auto;
      justify-content: flex-end;
      a.devis, a.rea {
        font-size: 12px;
        padding: 5px 8px;
      }
    }
  }
  a.devis, a.rea {
    color: #fff;
    text-decoration: none;
    padding: 5px 12px;
    border-radius: 50px;
    border: 2px solid #fff;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-box-shadow: 0px 0px 18px -2px rgba(255,255,255,1);
    -moz-box-shadow: 0px 0px 18px -2px rgba(255,255,255,1);
    box-shadow: 0px 0px 18px -2px rgba(255,255,255,1);
    background-color: transparent;
    .transition(all .2s);
    &:hover {
      background-color: #fff;
      color: @brand-primary;
      .transition(all .2s);
    }
    i {
      margin-left: 10px;
      @media (max-width: @screen-md-max) {
        margin-left: 5px;
      }
    }
  }
  a.devis {
    background-color: #fff;
    color: @brand-primary;
    &:hover {
      background-color: transparent;
      color: #fff;
      .transition(all .2s);
    }
  }
  a.rea {
    right: 160px;
    @media (max-width: @screen-md-max) {
      right: 170px;
    }
  }
  .secondary-nav {
    margin-top: 5px;
    margin-bottom: 10px;
    text-align: right;
    position: relative;
    top: 0;
    transition: all .2s;
    > ul {
      > li {
        > a {
          text-transform: none;
        }
      }
    }
  }
}
.menu-open {
  #navigation {
    @media (max-width: @screen-md-max) {
      height: 100% !important;
      background-color: #000;
      overflow-y: scroll;
      padding-bottom: 50px;
    }
    ul {
      display: block;
      padding-top: 40px;
      ul {
        display: none;
        &.open {
          display: block;
          padding: 10px;
          position: static;
          width: 100%;
        }
        > li {
          > a {
            padding: 5px;
          }
        }
      }
    }
    .secondary-nav {
      text-align: left;
      padding-top: 60px;
      ul {
      }
    }

  }
}
#coord {
	position: relative;
	z-index: 1;
	padding-top: 150px;
	@media (max-width: @screen-xs-max) {
		padding-top: 45px;
	}
	p {
		color: #676767;
		font-size: @font-size-small;
		letter-spacing: 1px;
		line-height: 1.500em;
	}
	strong {
		font-weight: 500;
	}
	a {
		color: #ffffff;
		text-decoration: underline;
		&:hover, &:focus {
			text-decoration: none;
		}
	}
	a:last-child {
		color: #676767;
	}
}
.nav-wrapper {
	position: relative;
	height: 100%;
	width: 100%;
	overflow: hidden;
	.navside {
		position: absolute;
		top: 0;
		right: -30px;
		bottom: 0;
		left: 0;
		overflow-y: scroll;
	}
}
#sidebar {
	position: absolute;
	z-index: 10;
	padding: 165px 70px 85px;
	height: 100vh;
	min-height: 678px;
	width: 100%;
	top: 0;
	left: -100%;
	overflow: hidden;
	.transition(all .35s);
	.square {
		svg {
			position: absolute;
			z-index: 0;
			top: 0;
			right: 50%;
			margin-right: -73px;
			opacity: .8;
		}
	}
	&.menu-open {
		left: 0;
	}
	@media (max-width: @screen-md-max) {
		padding: 165px 30px 85px;
	}
	@media (max-width: @screen-sm-max) {
		padding: 210px 30px 85px;
		min-height: 0;
		background-color: fade(#0a0a0a, 95%);
		overflow-y: scroll;
		.square {
			display: none;
		}
	}
	@media (max-width: @screen-xs-max) {
		padding: 210px 30px 30px;
	}
}
@media (max-width: @screen-sm-max) {
	body.menu-open {
		overflow: hidden;
	}
}

#header.fixed {
  .transition(all .2s);
	#navigation {
    height: 70px;
    background-color: #000;

    a.devis {
      top: 15px;
      .transition(all .2s);
    }
    a.trigger-menu {
      @media (max-width: @screen-md-max) {
        top: 15px;
      }
    }
    .secondary-nav {
      margin-top: -30px;
      margin-bottom: 15px;
      transition: all .2s;
    }
    .cta-links {
      top: 13px;
    }
  }
}

#banner {
	position: relative;
	height: 100vh;
	min-height: 678px;
	width: 100%;
	background-color: #151515;
	overflow: hidden;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	@media (max-width: @screen-xs-max) {
		height: auto;
		min-height: 0;
  }
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.1);
    z-index: 1;
  }
}
