body.node-type-webform {
	article.node-webform {
		.content-body;
	}
	.alert {
		margin: 120px auto 0;
		max-width: 700px;
	}
}

form {
	.form-item {
		margin: 15px 0;
		&.webform-component-date {
			.form-item {
				margin: 0;
				display: inline-block;
				vertical-align: middle;
				width: calc(~"33.3333333% - 11px");
			}
		}
		&.webform-component-file {
			.form-submit {
				.btn-primary;
				margin-top: 5px;
			}
		}
		&.webform-component-radios, &.webform-component-checkboxes {
			.form-item {
				margin: 0 15px 0 0;
				display: inline-block;
				vertical-align: middle;
			}
		}
		&.webform-component-select {
			select {
				.form-control;
			}
		}
	}
}