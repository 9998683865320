.blog-article {
	margin: 20px 0 80px 0;
	display: flex;
	justify-content: space-between;
	@media (max-width: @screen-xs-max) {
		display: block;
	}
	.pic {
		width: 40%;
		@media (max-width: @screen-xs-max) {
			width: 100%;
		}
		img {
			max-width: 100%;
		}
	}
	.text-content {
		width: 55%;
		@media (max-width: @screen-xs-max) {
			width: 100%;
		}
		h2 {
			margin-top: 20px;
			margin-bottom: 0;
			font-size: 1.5em;
			a {
				text-decoration: none;
				&:hover {
					color: #000;
				}
			}
		}
		p.date{
			font-style: italic;
			font-size: .9em;
		}
		p {
			line-height: 2em;
			letter-spacing: .1em;
		}
	}
	&:nth-child(2) {
		.pic {
			order: 2;
		}
		.text-content {
			order: 1;
		}
	}
}
.blog-content-body {
	padding-top: 40px;
	padding-bottom: 100px;
	.img-left {
		float: left;
		margin: 0 20px 5px 0;
	}
	h2 {
		margin-top: 20px;
		margin-bottom: 0;
		font-size: 1.5em;
	}
	p.date{
		font-style: italic;
		font-size: .9em;
	}
	p {
		line-height: 2em;
		letter-spacing: .1em;
	}		
}