/**
 * Use em or rem font-size in Bootstrap 3
 */

@font-size-root: 16px;
@font-unit: 0rem; // Pick em or rem here


// Convert all variables to em

@font-size-base:  1 + @font-unit;
@font-size-large: 1.125 + @font-unit; //18px
@font-size-small: 0.875 + @font-unit; // 14px

@font-size-h1:            4.500 + @font-unit; // 72px
@font-size-h2:            3.375 + @font-unit; //54px
@font-size-h3:            1.375 + @font-unit; //22px
@font-size-h4:            1.125 + @font-unit; //18px
@font-size-h5:            @font-size-base; //16px
@font-size-h6:            0.875 + @font-unit; //14px

@line-height-base:        1.625;
@line-height-computed:    (@font-size-base * @line-height-base);

@padding-base-vertical:     ((6px / @font-size-root) + @font-unit);
@padding-base-horizontal:   ((12px / @font-size-root) + @font-unit);

@padding-large-vertical:    ((10px / @font-size-root) + @font-unit);
@padding-large-horizontal:  ((16px / @font-size-root) + @font-unit);

@padding-small-vertical:    ((5px / @font-size-root) + @font-unit);
@padding-small-horizontal:  ((10px / @font-size-root) + @font-unit);

@padding-xs-vertical:       ((1px / @font-size-root) + @font-unit);
@padding-xs-horizontal:     ((5px / @font-size-root) + @font-unit);

@border-radius-base:        ((0 / @font-size-root) + @font-unit);
@border-radius-large:       ((0 / @font-size-root) + @font-unit);
@border-radius-small:       ((0 / @font-size-root) + @font-unit);

@caret-width-base:          ((4px / @font-size-root) + @font-unit);
@caret-width-large:         ((5px / @font-size-root) + @font-unit);

//** Default `.form-control` height
@input-height-base:         (@line-height-computed + (@padding-base-vertical * 2));
//** Large `.form-control` height
@input-height-large:        ((@font-size-large * @line-height-large) + (@padding-large-vertical * 2));
//** Small `.form-control` height
@input-height-small:        ((@font-size-small * @line-height-small) + (@padding-small-vertical * 2));


// Set navbar paddings
@navbar-padding-horizontal:        floor((@grid-gutter-width / 2));
@navbar-padding-vertical:          ((@navbar-height - (@font-size-root * @line-height-base)) / 2);


// Set root font size

html {
    font-size: @font-size-root;
}


// Change styling for elements that mix pixels and ems

pre {
  font-size: ( @font-size-base - (1 / @font-size-root) );
}

.form-control-feedback {
  width: calc(@input-height-base ~" + 2px");
  height: calc(@input-height-base ~" + 2px");
}
.input-lg + .form-control-feedback {
  width: calc(@input-height-large ~" + 2px");
  height: calc(@input-height-large ~" + 2px");
}
.input-sm + .form-control-feedback {
  width: calc(@input-height-small ~" + 2px");
  height: calc(@input-height-small ~" + 2px");
}

.form-control {
    height: calc(@input-height-base ~" + 2px");
}

.form-horizontal {
    .radio, .checkbox, .radio-inline, .checkbox-inline {
        padding: @padding-base-vertical;
        border: 1px solid transparent;
    }
    .radio, .checkbox {
        min-height: calc((@line-height-computed + @padding-base-vertical) ~"+ 1px");
    }
    .form-control-static {
        padding-top: @padding-base-vertical;
        border-top: 1px solid transparent;
        padding-bottom: @padding-base-vertical;
        border-bottom: 1px solid transparent;
    }

    @media (min-width: @screen-sm-min) {
        .control-label {
            padding-top: @padding-base-vertical;
            border-top: 1px solid transparent;
        }
    }
}
