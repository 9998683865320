#slider {
	background-color: #151515;
  @media (max-width: @screen-xs-max) {
    padding-top: 200px;
  }

	.carousel {
		.slide {
			position: relative;
      @media (max-width: @screen-xs-max) {
        height: 40vh;
        min-height: auto;
      }
			height: 100vh;
			min-height: 678px;
			width: 100%;
			background-color: #151515;
      overflow: hidden;
			img {
				position: absolute;
			    top: 0;
			    left: 15%;
			    height: 100%;
			    width: auto;
			    max-width: none;
			    @media (max-width: @screen-xs-max) {
            left: 0;
            width: 100%;
            height: auto;
			    }
			}
			.square {
				svg {
          @media (max-width: @screen-xs-max) {
            display: none;
          }
          @media (max-width: @screen-sm-max) {
            right: 55%;
          }
					position: absolute;
					z-index: 1;
					top: 0;
          right: 65%;
					margin-right: -73px;
				}
			}
		}
	}
  .owl-carousel .owl-controls {
    @media (max-width: @screen-xs-max) {
      left: 50%!important;
      bottom: 20px!important;
      transform: translateX(-50%)!important;
    }
  }
}
#content-banner {
	h2 {
		font-size: 1rem;
		color: #fff;
		margin: 0 0 0.8rem 0;
		font-weight: normal;
	}
}
