.banner-wrapper {
	position: relative;
	height: 100vh;
	min-height: 678px;
	width: 100%;
	@media (max-width: @screen-xs-max) {
		height: auto;
		min-height: 0;
  }
  a.mouse-link {
    @media (max-width: @screen-xs-max) {
      display: none;
    }
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -9px;
    z-index: 10;
    animation: MoveUpDown 2s linear infinite;
  }
}
@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
#content-banner {
	position: absolute;
	bottom: 25vh;
	left: 0;
	z-index: 2;
	width: 100%;
	color: #FFF;
}
body.front, body.page-taxonomy-term {
	#content-banner {
    top: 110px;
    bottom: auto;
    padding: 0 80px;
    @media (max-width: @screen-sm-max) {
      top: 150px;
    }
    h1 {
      font-size: 3rem;
    }
    @media (max-width: @screen-xs-max) {
      position: static;
      color: #a0a0a0;
      padding: 40px 40px 0;
    }
    .banner-title {
      width: 360px;
      margin-left: 0;
      font-size: 3rem;
      @media (max-width: @screen-md-max) {
        /*width: auto;*/
      }
      @media (max-width: @screen-xs-max) {
        text-align: center;
        width: auto;
        h1 {
          color: #a0a0a0;
        }
      }
    }
    .banner-subtitle {
      width: 20%;
      @media (max-width: @screen-xs-max) {
        width: 100%;
        text-align: center;
      }
      font-size: 20px;
      font-weight: 700;
      line-height: 1.2;
    }
	}
}
body:not(.front) {
	#content-banner {
		@media (max-width: @screen-xs-max) {
			position: relative;
			bottom: auto;
			left: 0;
			padding-top: 240px;
			padding-bottom: 75px;
		}
	}
}
#slider {
  .owl-carousel {
    .owl-controls {
      position: absolute;
      left: 100px;
      bottom: 100px;
      .owl-dots {
        display: flex;
        .owl-dot {
          margin-right: 10px;
          span {
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 100%;
            border: 1px solid #fff;
          }
          &.active {
            span {
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}
main {
  h2 {
    font-size: 26px;
    color: #000;
    margin: 10px 0 20px 0;
  }
}
.node-type-home #content {
  //background-image: url(../../img/lines-2.png);
  /*background-image: url(../../img/neon-bg.svg);
  background-position: center 200px;
  background-repeat: no-repeat;
  background-size: 90%;*/
}
.intro {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  margin-bottom: 50px;
  @media (max-width: @screen-md-max) {
    flex-wrap: wrap;
  }
  .intro-image {
    width: 100%;
    margin-right: 30px;
    img {
      width: 100%;
    }
    @media (max-width: @screen-md-max) {
      width: 50%;
      margin: 0 auto 40px;
    }
    @media (max-width: @screen-xs-max) {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }
  }
  img {
  }
  .intro-text {
    width: 40%;
    color: #a0a0a0;
    @media (max-width: @screen-md-max) {
      width: 100%;
    }
    a {
      background-color: #fff;
      border-radius: 50px;
      padding: 5px 40px;
      color: #000;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 700;
      border: 1px solid #000;
      margin-top: 30px;
    }
  }
}
.blocs {
  padding: 20px 25%;
  //display: flex;
  @media (max-width: @screen-md-max) {
    padding: 0 20px;
    //flex-wrap: wrap;
  }
  /*> div {
    width: 50%;
    @media (max-width: @screen-md-max) {
      width: 100%;
    }
    &:nth-child(1) {
      padding-right: 10px;
    }
    &:nth-child(2) {
      padding-left: 10px;
    }
  }*/
}
.home-categories {
  position: relative;
  min-height: 1000px;
  margin-top: 50px;
  @media (max-width: @screen-md-max) {
    margin-top: 0;
    min-height: auto;
  }
  .category-intro {
    width: 25%;
    height: 380px;
    padding-right: 20px;
    position: absolute;
    top: 0;
    left: 0;
    @media (max-width: @screen-md-max) {
      height: auto;
      width: 100%;
      padding: 0;
      margin-bottom: 20px;
      position: static;
    }
  }
  .category {
    display: block;
    width: 25%;
    padding: 140px 40px 20px 40px;
    border: 1px solid #eee;
    height: 380px;
    position: absolute;
    text-decoration: none;
    margin-bottom: 20px;
    .transition(all .2s);
    @media (max-width: @screen-md-max) {
      width: 100%;
      height: auto;
      position: relative;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 80px;
      left: 40px;
      background-repeat: no-repeat;
      background-size: 100%;
    }
    h3 {
      margin: 20px 0 10px 0;
      color: #000;
      font-size: 1rem;
    }
    p, .description {
      font-size: .9rem;
      color: #333;
    }
    span {
      font-size: .75rem;
      text-transform: uppercase;
      color: #000;
      font-weight: 700;
    }
    &:nth-child(2) {
      top: 0;
      left: 25%;
      @media (max-width: @screen-md-max) {
        top: 0;
        left: 0;
      }
      &:before {
        width: 48px;
        height: 44px;
        background-image: url(../../img/product-1.svg);
      }
      &:hover {
        &:before {
          background-image: url(../../img/product-1-h.svg);
        }
      }
    }
    &:nth-child(3) {
      top: 160px;
      left: calc(~'50% - 1px');
      @media (max-width: @screen-md-max) {
        top: 0;
        left: 0;
      }
      &:before {
        width: 34px;
        height: 46px;
        background-image: url(../../img/product-3.svg);
      }
      &:hover {
        &:before {
          background-image: url(../../img/product-3-h.svg);
        }
      }
    }
    &:nth-child(4) {
      top: 379px;
      left: 25%;
      @media (max-width: @screen-md-max) {
        top: 0;
        left: 0;
      }
      &:before {
        width: 46px;
        height: 40px;
        background-image: url(../../img/product-2.svg);
      }
      &:hover {
        &:before {
          background-image: url(../../img/product-2-h.svg);
        }
      }
    }
    &:nth-child(5) {
      top: 539px;
      left: calc(~'50% - 1px');
      @media (max-width: @screen-md-max) {
        top: 0;
        left: 0;
      }
      &:before {
        width: 55px;
        height: 40px;
        background-image: url(../../img/product-4.svg);
      }
      &:hover {
        &:before {
          background-image: url(../../img/product-4-h.svg);
        }
      }
    }
    &:nth-child(6) {
      top: 380px;
      left: calc(~'75% - 2px');
      @media (max-width: @screen-md-max) {
        top: 0;
        left: 0;
      }
      &:before {
        width: 140px;
        height: 72px;
        background-image: url(../../img/product-5.svg);
      }
      &:hover {
        &:before {
          background-image: url(../../img/product-5-h.svg);
        }
      }
    }
    &:hover {
      background-color: @brand-primary;
      border-color: @brand-primary;
      transform: translate(-10px, -10px);
      z-index: 5;
      .transition(all .2s);
      h3 {
        color: #fff;
      }
      p, .description {
        color: #fff;
      }
      span {
        color: #fff;
      }
    }
  }
}

.md-width {
	margin: 0 auto;
	max-width: 970px;
}
.no-marge {
	margin: 0;
}
.no-gap {
	padding: 0;
}
// Content Body
.content-body {
	position: relative;
	max-width: 970px;
	margin: 0 auto;
	padding: 90px 15px 210px;
	@media (max-width: @screen-xs-max) {
		padding-top: 30px;
		padding-bottom: 180px;
	}
	// All
	& > * {
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
	}

	// Margin
	p {
		margin-top: 0;
		margin-bottom: 30px;
		&:last-child {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	ol {
		.clearfix;
		margin-top: 0;
		margin-bottom: 0;
		padding: 0;
		list-style: none;
		li {
			position: relative;
			display: inline-block;
			vertical-align: top;
			margin: 30px 0;
			padding: 0 30px 0  65px;
			width: 50%;
			counter-increment: step-counter;
			@media (max-width: @screen-xs-max) {
				width: 100%;
			}
			&:before {
				content: counter(step-counter);
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				height: 50px;
				width: 50px;
				background-color: #FFF;
				border: 3px solid #e0e0e0;
				font-size: 1.500rem;
				line-height: 44px;
				text-align: center;
			}
			&:nth-child(-n+2) {
				margin-top: 0;
			}
		}
	}


	// Aside
	& > aside, & > .full-width {
		max-width: 940px;
	}

	// Colonnes
	& > .row {
		margin-top: 45px;
		max-width: 970px;
		& + .row {
			margin-top: 0;
		}
		.col-md-4 {
			padding: 0;
			img {
				display: block;
				height: auto;
				width: 100%;
			}
			& > *:not(p) {
				padding: 0 40px;
				& + p {
					padding: 0 40px;
				}
			}
		}

		.col-md-6:first-child {
			@media (min-width: @screen-md-min) {
				padding-left: 0;
			}
		}

		.col-md-6:last-child {
			@media (min-width: @screen-md-min) {
				padding-right: 0;
			}
		}
	}
}

// Lead
p.lead {
	font-size: 1.33em;
	line-height: 1.5em;
	color: @brand-primary;
	font-weight: 300;
	margin-bottom: @line-height-computed;
}

// Evidence
p.evidence, div.evidence {
	color: #fff;
	background-color: @brand-primary;
	padding: @line-height-computed*2;
	margin-bottom: @line-height-computed;

	a {
		color: #fff;
	}

	a.btn {
		background-color: #fff;
		color: @brand-primary;

		&:hover {
			background-color: darken(#fff, 10%);
		}
	}
}

// Img
img {
	max-width: 100%;
	height: auto;

	&[style*="left"] {
		margin-right: @line-height-computed;
		margin-bottom: @line-height-computed;
	}

	&[style*="right"] {
		margin-left: @line-height-computed;
		margin-bottom: @line-height-computed;
	}
}

// Table
table {
	border: 1px solid #d8d8d8;
	margin-bottom: @line-height-computed;
	width: 100%;

	th {
		background-color: @brand-primary;
		color: #fff;
		text-transform: uppercase;
		font-weight: 400;
	}

	td {
		background-color: #f0f0f0;
	}

	tr.even td {
		background-color: darken(#f0f0f0, 5%);
	}

	td, th {
		padding: @line-height-computed/2 @line-height-computed;
	}
}

// Iframe responsive
.iframe-embed {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
	margin-bottom: @line-height-computed;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

// Boutons
.btn1 {
	background-color: @brand-primary;
	border: 0;
	color: #fff;
	&:hover, &:focus {
		background-color: darken(@brand-primary, 15%);
		color: #fff;
	}
}

.btn2 {
	background-color: #000;
	border: 0;
	color: #fff;
}

// Types
h1 {
	color: #FFF;
	margin: 0 0 60px;
	font-weight: 700;
	line-height: 0.903em;
  font-size: 4rem;
	@media (max-width: @screen-xs-max) {
		margin: 0 0 30px;
		font-size: 2.250rem;
	}
}
h2 {
	margin: 70px 0 30px;
	font-weight: 700;
	line-height: 1.204em;
	@media (max-width: @screen-xs-max) {
		margin: 30px 0;
		font-size: 1.875rem;
	}
}
h3 {
	margin: 30px 0;
	font-weight: 700;
	line-height: normal;
}
a {
	text-decoration: underline;
}

#site-map {
	.content-body;
}

form {
	.form-submit {
		.btn1;
	}
}
.page-taxonomy-term {
  .intro {
    margin-bottom: 10px;
    > .container {
      align-items: flex-start;
      .intro-text {
        min-height: 450px;
      }
    }
  }
  .description {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: @screen-sm-max) {
      width: 100%;
    }
  }
  .produits {
	  margin-top: 20px;
	  h2 {
		  text-align: center;
		  position: relative;
		  padding-bottom: 10px;
		  margin-bottom: 40px;
		  &:after {
			  content: '';
			  display: block;
			  width: 40px;
			  height: 1px;
			  background-color: #707070;
			  position: absolute;
			  bottom: 0;
			  left: 48%;
		  }
    }
    .produits-list {
      display: flex;
      flex-wrap: wrap;
      @media (max-width: @screen-sm-max) {
        flex-wrap: wrap;
      }
      .produit {
        width: 30%;
        @media (max-width: @screen-sm-max) {
          width: 100%;
        }
        &:nth-child(3n+2) {
          margin-left: 4.5%;
          margin-right: 4.5%;
          @media (max-width: @screen-sm-max) {
            margin: 0;
          }
        }
        a {
          display: block;
          height: 200px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          text-decoration: none;
          position: relative;
          margin-bottom: 30px;
          &:after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 53%, rgba(0,0,0,0.8) 100%);
            background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 53%,rgba(0,0,0,0.8) 100%);
            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 53%,rgba(0,0,0,0.8) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 );
            z-index: 1;
            .transition(all .2s);
          }
          h3 {
            position: absolute;
            bottom: 15px;
            left: 15px;
            color: #fff;
            font-size: 16px;
            margin-bottom: 0;
            z-index: 2;
            .transition(all .2s);
          }
          &:hover {
            h3 {
              bottom: 40%;
              .transition(all .2s);
            }
            &:after {
              background-color: rgba(0,0,0,.6);
              .transition(all .2s);
            }
          }
        }
      }
    }
  }
}
.node-type-produit {
  .description {
    padding: 40px 200px;
    @media (max-width: @screen-sm-max) {
      padding: 20px 0;
    }
  }
  .banner-wrapper {
    height: auto;
    #banner {
      height: 700px;
      @media (max-width: @screen-sm-max) {
        height: 600px;
      }
    }
    h1 {
      text-align: center;
    }
  }
  .product-infos {
    position: relative;
    z-index: 2;
  }
  .produit-pic {
    height: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: -150px;
    display:none;
  }
  .realisations {
	  margin-top: 20px;
	  h2 {
		  text-align: center;
		  position: relative;
		  padding-bottom: 10px;
		  margin-bottom: 40px;
		  &:after {
			  content: '';
			  display: block;
			  width: 40px;
			  height: 1px;
			  background-color: #707070;
			  position: absolute;
			  bottom: 0;
			  left: 48%;
		  }
    }
    .realisations-list {
      display: flex;
      flex-wrap: wrap;
      .realisation {
        width: 30%;
        @media (max-width: @screen-sm-max) {
          width: 100%;
        }
        &:nth-child(3n+2) {
          margin-left: 4.5%;
          margin-right: 4.5%;
        }
        a {
          display: block;
          height: 200px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          text-decoration: none;
          position: relative;
          margin-bottom: 30px;
          &:after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 53%, rgba(0,0,0,0.8) 100%);
            background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 53%,rgba(0,0,0,0.8) 100%);
            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 53%,rgba(0,0,0,0.8) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 );
            z-index: 1;
            .transition(all .2s);
          }
          h3 {
            position: absolute;
            bottom: 15px;
            left: 15px;
            color: #fff;
            font-size: 16px;
            margin-bottom: 0;
            z-index: 2;
            .transition(all .2s);
          }
          &:hover {
            h3 {
              bottom: 40%;
              .transition(all .2s);
            }
            &:after {
              background-color: rgba(0,0,0,.6);
              .transition(all .2s);
            }
          }
        }
      }
    }
  }
}
.scrollDown {
	/* text-align: center;
	a {
		position: absolute;
		bottom: 10vh;
		color: #fff;
		font-size: 4.500rem;
		line-height: 1.000em;
	} */
	a {
		display: block;
	    position: absolute;
	    bottom: 10vh;
	    left: 50%;
	    margin-left: -5%;
	    width: 10%;
      text-align: center;
      z-index: 2;
	    img {
        position: relative;
        top: 0;
        margin-bottom: 20px;
        animation: scroll 1s linear infinite;
        -webkit-animation: scroll 1s linear infinite; /* Safari and Chrome */
        .transition(all ease-out .35s);
	    }
	    &:hover, &:focus {
	    	img {
	    		margin-bottom: 10px;
	    	}
	    }
    }
}

@keyframes scroll
{
    0% {top: 0;}
    50% {top: 10px;}
    100% {top: 0;}
}

@-webkit-keyframes scroll /* Safari and Chrome */
{
    0% {top: 0;}
    50% {top: 10px;}
    100% {top: 0;}
}
