#cta {
	position: fixed;
	z-index: 15;
	bottom: 0;
	width: 100%;
	a {
		float: left;
		display: block;
		padding: 44px 15px;
		width: 50%;
		color: #FFF;
		font-size: 2rem;
		font-weight: 500;
		letter-spacing: 1px;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		.transition(all .35s);
		@media (max-width: @screen-sm-max) {
			font-size: 1.2rem;
		}
		@media (max-width: @screen-xs-max) {
			padding: 26px 15px;
			font-size: .9rem;
		}
		.fa {
			margin-left: 15px;
		}
		span {
			font-weight: 900;
		}
		&:hover, &:focus {
			text-decoration: none;
		}

		&:first-child {
			background-color: rgba(11, 11, 11, 0.9);
			&:hover, &:focus {
				background-color: darken(rgba(11, 11, 11, 0.9), 15%);
			}
		}
		&:nth-child(2) {
			background-color: @brand-primary;
			&:hover, &:focus {
				background-color: darken(@brand-primary, 15%);
			}
		}
	}
}
footer {
  .footer-content {
    padding: 30px 0;
    border-top: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    @media (max-width: @screen-sm-max) {
      flex-wrap: wrap;
    }
    h2 {
      margin: 0 0 0 0;
      color: #000;
      font-size: 1rem;
    }
    p {
      margin: 0;
      font-size: .9rem;
      font-weight: 500;
      line-height: 1.2;
    }
    i {
      color: #000;
      margin-left: 10px;
    }
    > div {
      @media (max-width: @screen-sm-max) {
        width: 100%;
      }
      &:nth-child(4) {
        width: 30%;
        text-align: right;
        @media (max-width: @screen-sm-max) {
          width: 100%;
        }
      }
    }
  }
}
