#realisations {
  padding-bottom: 70px;
  background-color: #0c0c0c;
  overflow: hidden;
  @media (max-width: @screen-xs-max) {
    padding-bottom: 0;
    min-height: 100vh;
  }
  .filters {
    position: absolute;
    z-index: 5;
    width: 100%;
    .md-width {
      position: relative;
    }
    .view-filters {
      .filters-select {
        color: #000;
        height: 40px;
        @media (min-width: @screen-xs-max) {
          display: none;
        }
      }
      .clearfix;
      position: absolute;
      top: 210px;
      text-align: center;
      @media (max-width: @screen-xs-max) {
        top: 240px;
        width: 100%;
      }
      @media (min-width: @screen-sm-min) {
        display: block !important;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        @media (max-width: @screen-xs-max) {
          display: none;
        }
        li {
          display: inline-block;
          vertical-align: middle;
          margin: 0;
          @media (max-width: @screen-xs-max) {
            display: block;
          }
          a {
            display: block;
            border-bottom: 2px solid transparent;
            color: #fff;
            font-weight: 700;
            font-size: 0.75rem;
            line-height: 2.5em;
            text-decoration: none;
            text-transform: uppercase;
            @media (max-width: @screen-xs-max) {
              display: inline-block;
            }
            &:hover,
            &:focus,
            &.active {
              border-color: #fff;
              text-decoration: none;
            }
          }
          & ~ li {
            margin-left: 20px;
            @media (max-width: @screen-xs-max) {
              margin: 0;
            }
          }
        }
      }
    }
  }
  .trigger-filter-wrapper {
    position: absolute;
    top: 210px;
    width: 100%;
    text-align: center;
    @media (max-width: @screen-md-max) {
      display: none !important;
    }
    .trigger-filter {
      color: #fff;
      font-size: 1rem;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: normal;
      text-decoration: none;
      text-transform: uppercase;
      .transition(all 0.2s);
      .fa {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
      }
      &:hover,
      &:focus {
        color: @brand-primary;
      }
    }
  }
  .bef-select-as-links {
    .clearfix;
    position: absolute;
    top: 210px;
    text-align: center;
    @media (max-width: @screen-xs-max) {
      display: none;
      top: 240px;
      width: 100%;
    }
    @media (min-width: @screen-sm-min) {
      display: block !important;
    }
    .form-type-bef-link {
      display: inline-block;
      vertical-align: middle;
      margin: 0;
      @media (max-width: @screen-xs-max) {
        display: block;
      }
      a {
        display: block;
        border-bottom: 2px solid transparent;
        color: #fff;
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 2.5em;
        text-decoration: none;
        text-transform: uppercase;
        @media (max-width: @screen-xs-max) {
          display: inline-block;
        }
        &:hover,
        &:focus,
        &.active {
          border-color: #fff;
          text-decoration: none;
        }
      }
      & ~ .form-type-bef-link {
        margin-left: 20px;
        @media (max-width: @screen-xs-max) {
          margin: 0;
        }
      }
    }
  }
  .realisation {
    float: left;
    width: 18%;
    @media (max-width: @screen-md-max) {
      width: 24%;
    }
    @media (max-width: @screen-sm-max) {
      width: 32%;
    }
    @media (max-width: @screen-xs-max) {
      width: 48%;
    }
    @media (max-width: 400px) {
      width: 100%;
    }
    .transition(all 0.5s);
    .real-content {
      position: relative;
      padding-bottom: 100%;
      height: 0;
    }
    figure {
      position: relative;
      z-index: 1;
      background-color: #0c0c0c;
      overflow: hidden;
      text-align: center;
      cursor: pointer;
      .transition(all 0.5s);
      &:after {
        content: "";
        display: block;
        position: absolute;
        z-index: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 50%;
        background: -moz-linear-gradient(
          top,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 100%
        );
        background: -webkit-linear-gradient(
          top,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 100%
        );
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
        opacity: 0;
        .transition(all 0.5s);
      }
      img {
        position: relative;
        display: block;
        opacity: 0.75;
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2);
        -webkit-backface-visibility: hidden;
        .transition(all 0.5s);
      }
      figcaption {
        padding: 30px 40px;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        &:before,
        &:after {
          pointer-events: none;
        }
        h2 {
          position: absolute;
          z-index: 1;
          right: 0;
          bottom: 30px;
          left: 0;
          margin: 0;
          padding: 0 40px;
          color: #fff;
          font-size: 0.75rem;
          font-weight: 400;
          text-transform: uppercase;
          opacity: 0;
          -webkit-transform: translate(0, 200%);
          -moz-transform: translate(0, 200%);
          -ms-transform: translate(0, 200%);
          -o-transform: translate(0, 200%);
          transform: translate(0, 200%);
          -webkit-backface-visibility: hidden;
          .transition(all 0.5s);
        }
      }
      figcaption,
      a {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
      a {
        z-index: 2;
        font-size: 0;
        text-indent: -9999px;
        opacity: 0;
        white-space: nowrap;
      }
    }
    &:not(.disabled) {
      figure {
        &:hover,
        &:focus {
          z-index: 2;
          -webkit-transform: scale(1.2);
          -moz-transform: scale(1.2);
          -ms-transform: scale(1.2);
          -o-transform: scale(1.2);
          transform: scale(1.2);
          box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.52);
          &:after {
            opacity: 0.8;
          }
          img {
            opacity: 0.9;
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -ms-transform: scale(1);
            -o-transform: scale(1);
            transform: scale(1);
          }
          figcaption {
            h2 {
              opacity: 1;
              -webkit-transform: translate(0, 0);
              -moz-transform: translate(0, 0);
              -ms-transform: translate(0, 0);
              -o-transform: translate(0, 0);
              transform: translate(0, 0);
            }
          }
        }
      }
    }
    &.disabled {
      opacity: 0.25;
      figure {
        cursor: default;
        a {
          pointer-events: none;
          cursor: default;
        }
      }
      @media (max-width: @screen-xs-max) {
        display: none;
      }
    }
  }
}
.rea-list {
  margin-top: 400px;
  background-color: #fff;

  .rea-list-title {
    margin-left: auto;
    margin-right: auto;
    padding-top: 60px;
    padding-bottom: 60px;
    max-width: 960px;
    color: @text-color;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.875;
    text-align: center;

    h1 {
      margin: 0 0 30px;
      color: #0c0c0c;
      font-size: 3rem;
      font-weight: 700;
      line-height: 1.05;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .view-content,
  .rea-list-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 0;
    &:before {
      display: none;
    }
    article.realisation {
      float: none;
      width: 18%;
      margin-bottom: 20px;
      @media (max-width: @screen-md-max) {
        width: 30%;
      }
      @media (max-width: @screen-md-min) {
        width: 48%;
      }
      @media (max-width: @screen-sm-min) {
        width: 100%;
      }
    }
  }
}

body.page-nos-realisations {
  background-color: #0c0c0c;
  @media (min-width: @screen-md-min) {
    #header:not(.fixed) {
      #sidebar {
        height: 100%;
        #coord {
          padding-top: 45px;
        }
      }
    }
  }
}
body.page-taxonomy-term {
  #realisations {
    article.realisation {
      float: none;
      width: 18%;
      margin-bottom: 20px;
      @media (max-width: @screen-md-max) {
        width: 30%;
      }
      @media (max-width: @screen-md-min) {
        width: 48%;
      }
      @media (max-width: @screen-sm-min) {
        width: 100%;
      }
    }
  }
}
.more-rea {
  text-decoration: none;
  margin-bottom: 20px;
}
